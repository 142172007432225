import { jwtDecode } from "jwt-decode";
import jwtEncode from "jwt-encode";

import http, { setJwt } from "./httpService";
import axios from "axios";
const apiEndpoint = process.env.REACT_APP_SERVER_URL + "auth";
const userToken = "token";
const superAdminTempJWT = "superAdminTempJWT";
// const tokenKey = "msal.1a3eda36-b715-4b57-b846-bed5e0ed2442.idtoken";
let tokenKey;
export const getCredentials = async () =>
  fetch("/appSettings.json")
    .then((res) => res.json())
    .then((result) => {
      return result;
    })
    .catch(console.log);
//setJwt(getJwt());

export const login = async (loginUser) => {
  const { data: jwt } = await http.post(apiEndpoint, {
    email: loginUser.email,
    password: loginUser.password,
  });
  localStorage.setItem(userToken, jwt);
};

export const ssoJWTCheck = async () => {
  let tokenCred = await getCredentials();
  tokenKey = `msal.${tokenCred.Client_ID}.idtoken`;
  if (
    localStorage.getItem(tokenKey) &&
    localStorage.getItem(userToken) == undefined
  ) {
    const decodedSSOToken = jwtDecode(
      localStorage.getItem(tokenKey).toString()
    );
    http
      .post(`${process.env.REACT_APP_SERVER_URL}auth/getToken`, {
        email: decodedSSOToken.preferred_username,
      })

      .then((res) => {
        if (res != undefined) {
          setUserJWT(res.data);
        }
      })
      .catch((er) => {
        console.log(er);
        debugger;
        setUserJWT(localStorage.getItem(tokenKey));
      });
  }
};

export const setRegionToken = (regionValue) => {
  const token = localStorage.getItem(userToken);
  const decodeToken = jwtDecode(token);
  decodeToken.region = regionValue;
  const encodedToken = jwtEncode(decodeToken, "officeBox_jwtPrivateKey");
  const newToken = localStorage.setItem("token", encodedToken);
};
export const setUserJWT = (jwt) => {
  localStorage.setItem(userToken, jwt);
  debugger;
  const token = jwtDecode(jwt);

  // const token = {
  //   "preferred_username": "shehab.harhash@vodafone.com",
  //   "roles": [
  //     "panelAdmin"
  //   ],
  //   "actions": [
  //     {
  //       "role": "panelAdmin",
  //       "record": {
  //         "isCalculated": false,
  //         "_id": "61dc0eb16b2bec400c571f39",
  //         "name": "Egypt's Filtration Panel ",
  //         "panelAdmin": "shehab.harhash@vodafone.com",
  //         "maxCount": 5,
  //         "region": "egypt",
  //         "panelType": "filtration-panel",
  //         "displayOrder": 1,
  //         "__v": 0
  //       }
  //     },
  //     {
  //       "role": "panelAdmin",
  //       "record": {
  //         "isCalculated": false,
  //         "_id": "61dc0eb16b2bec400c571f39",
  //         "name": "Egypt's Filtration Panel ",
  //         "panelAdmin": "shehab.harhash@vodafone.com",
  //         "maxCount": 5,
  //         // "region": "egypt",
  //         "panelType": "filtration-panel",
  //         "displayOrder": 1,
  //         "__v": 0
  //       }
  //     },
  //     {
  //       "role": "panelAdmin",
  //       "record": {
  //         "isCalculated": false,
  //         "_id": "61dc0eb16b2bec400c571f39",
  //         "name": "Egypt's Filtration Panel ",
  //         "panelAdmin": "shehab.harhash@vodafone.com",
  //         "maxCount": 5,
  //         // "region": "egypt",
  //         "panelType": "filtration-panel",
  //         "displayOrder": 1,
  //         "__v": 0
  //       }
  //     },
  //   ],
  //   "iat": 1641818481
  // }

  let result = [];

  if (!token["region"]) {
    localStorage.setItem("setRegion", true);
  }

  // superAdmin
  if (token.roles.includes("superAdmin")) {
    localStorage.setItem("IsSuperAdmin", "true");
  } else {
    // token.actions.map(action => {
    //   result.push(action.record['region'] === undefined)
    // })

    // console.log(result);
    // if (!result.includes(false)) {
    //   localStorage.setItem("setRegion", true);
    // }
    localStorage.setItem("IsSuperAdmin", "false");
  }
};
export const setImpersonateJWT = (email) => {
  localStorage.setItem(superAdminTempJWT, localStorage.getItem(userToken));
  const jwt = getToken(email).then((res) => {
    localStorage.setItem(userToken, res);
  });
};

export const returnSuperAdmin = () => {
  // console.log(localStorage.getItem(superAdminTempJWT));
  if (localStorage.getItem(superAdminTempJWT)) {
    localStorage.setItem(userToken, localStorage.getItem(superAdminTempJWT));
    localStorage.setItem(superAdminTempJWT, "");
    localStorage.removeItem(superAdminTempJWT);
  }
};

export const getToken = async (email) => {
  http
    .post(`${process.env.REACT_APP_SERVER_URL}auth/getToken`, {
      email,
    })
    .then((r) => {
      debugger;
      return r.data;
    });
};
export const getCurrentUser = () => {
  try {
    const jwt = localStorage.getItem(userToken);
    return jwtDecode(jwt);
  } catch (error) {
    return null;
  }
};
export async function getCurrentSSOUser() {
  try {
    let tokenCred = await getCredentials();
    tokenKey = `msal.${tokenCred.Client_ID}.idtoken`;
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (error) {
    return null;
  }
}
export function getJwt() {
  return localStorage.getItem(userToken);
}

export const logout = () => {
  localStorage.removeItem(userToken);
};

export default {
  // login,
  setUserJWT,
  logout,
  getCurrentUser,
  getJwt,
  getToken,
  setImpersonateJWT,
  returnSuperAdmin,
  setRegionToken,
};
