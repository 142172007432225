import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AzureAD, LoginType, AuthenticationState } from "react-aad-msal";
import authProvider from "../../services/authProvider";
import "./login.css";
import axios from "axios";

import { setUserJWT, getCurrentUser } from "./../../services/authService";
import LandingNavBar from "./../home/navbar";
import LoginForm from "./loginForm";
const Login = () => {
  const history = useNavigate();
  const [user, setUser] = useState({});
  let [cred, setCred] = React.useState();
  useEffect(() => {
    const user = getCurrentUser();
    setUser(user);
    axios.post(`${process.env.REACT_APP_SERVER_URL}dashboard/setgeneral`, {
      link: window.location.href,
    });
    authProvider.then((r) => setCred(r));
  }, []);

  const handleRegister = () => {
    history("/region");
  };

  return cred ? (
    <AzureAD provider={cred}>
      {({ login, logout, authenticationState, error }) => {
        const isInProgress =
          authenticationState === AuthenticationState.InProgress;
        const isAuthenticated =
          authenticationState === AuthenticationState.Authenticated;
        const isUnauthenticated =
          authenticationState === AuthenticationState.Unauthenticated;
        if (isAuthenticated) {
          return (
            <React.Fragment>
              {history("/entrance")}
              <button onClick={logout} className='Button'>
                Logout
              </button>
            </React.Fragment>
          );
        } else if (isUnauthenticated) {
          return (
            <div className='login-pageContainer container-fluid'>
              <LandingNavBar />
              <main className='row login-form-wrapper'>
                <div className='login-container  col-md-8 col-sm-8 col-xs-8'>
                  <h1 className='login-body-title'> Login</h1>
                  {/* <LoginForm /> */}

                  <button
                    className='login-body-actionBtn1'
                    type='button'
                    onClick={login}
                  >
                    <span>Sign In as Vodafoner</span>
                  </button>
                </div>
              </main>
              <div className='col-lg-4 col-md-4 col-sm-10 col-xs-10'></div>
            </div>
          );
        }
      }}
    </AzureAD>
  ) : (
    <div></div>
  );
};

export default Login;
