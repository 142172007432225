import React, { useEffect, useState } from "react";
import SuperAdminNavBar from "../SuperAdminNavBar";
import { useLocation } from "react-router-dom";
import axios from "axios";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import * as XLSX from "xlsx";
import * as _ from "lodash";
import { toast, ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import styles from "./ViewEmailTemplate.module.css";
Quill.register("modules/imageResize", ImageResize);
var BackgroundStyle = Quill.import("attributors/style/background");
Quill.register(BackgroundStyle, true);
const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { align: "" },
      { align: "center" },
      { align: "right" },
      { align: "justify" },
    ],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize"],
  },
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "align",
];
function useForceUpdate() {
  const [value, setValue] = React.useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here
  // is better than directly setting `value + 1`
}
function ViewEmailTemplate() {
  const forceUpdate = useForceUpdate();
  const location = useLocation();
  const [templateData, setTemplateData] = React.useState(
    location.state.state.templateData
  );
  const [templateDataBody, setTemplateDataBody] = React.useState("");
  const [uploadMembers, setuploadedMembers] = React.useState([]);
  const [subject, setSubject] = React.useState(location.state.state.subject);
  const [id, setID] = React.useState(location.state.state.id);
  const [TemplateType, setTemplateType] = React.useState("system");

  const [searchText, setSearchText] = useState("");
  const [reciepients, setReciepients] = useState([]);

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [loadBytes, setLoadBytes] = React.useState(false);
  const [category, setCategory] = React.useState("Only Me");
  const [Region, setRegion] = React.useState("India");
  const getMailTemplate = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}templates/getone`, {
        id,
      })
      .then((res) => {
        // res.data.Template = res.data.Template.replace("{{code}}", "55555");
        // console.log(res.data.Template.replace("{{code}}", "55555"));
        setTemplateData(res.data.Template);
        setSubject(res.data.MailSubject);
        setTemplateType(res.data.MailType);
      });
  };
  const EditTemplate = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}templates/editDefault`, {
        templateData,
        subject,
        id,
      })
      .then((res) => {
        getMailTemplate();
      });
  };
  useEffect(() => {
    if (document.getElementById("bodytochange")) {
      console.log(document.getElementById("bodytochange"));
      setTemplateDataBody(
        document.getElementById("bodytochange").innerHTML.toString()
      );
      forceUpdate();
    }
  }, [templateData]);
  useEffect(() => {
    getMailTemplate();
    getTemplateParticipants();
    setTimeout(() => {
      setLoadBytes(true);
    }, 3000);
  }, []);
  const convertStringToHTML = (htmlString) => {
    const parser = new DOMParser();
    const html = parser.parseFromString(htmlString, "text/html");

    return html;
  };
  const sendEmail = () => {
    if (TemplateType != "custom") {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}templates/sendtest`, {
          id,
        })
        .then((res) => {
          toast("Test Email Sent Successfully !!", { autoClose: 5000 });
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}templates/sendcustom`, {
          sendto: category,
          region: Region,
          id,
        })
        .then((res) => {
          toast("Test Email Sent Successfully !!", { autoClose: 5000 });
        });
    }
  };

  const addsendtoList = (e) => {
    e.preventDefault();

    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      let dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      let x = [];
      for (let index = 1; index < dataParse.length; index++) {
        const element = dataParse[index];
        let x = uploadMembers;
        x.push({ name: element[0], email: element[1] });
        console.log(x);
        setuploadedMembers(x);
        forceUpdate();
      }
      uploadEmails(uploadMembers);
    };
    reader.readAsBinaryString(f);
  };
  const uploadEmails = (e) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}templatesto/uploadmembers`, {
        arr: e,
        tempID: id,
      })
      .then((res) => {
        window.location.reload();
      });
  };
  const uploadImage = (e) => {
    e.preventDefault();

    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(f);
    reader.onload = function () {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}templates/uploadcertbg`, {
          imageBytes: reader.result,

          // userName: auth.getCurrentUser().preferred_username.split(".")[0],
        })
        .then((r) => {
          window.location.reload();
        });
    };
  };
  const sendToAll = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}templatesto/sendToAll`)
      .then((res) => {
        window.location.reload();
      });
  };
  const getTemplateParticipants = () => {
    setReciepients([]);
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}templatesto/getMailtemplates`, {
        searchText,
        tempID: id,
        page: pageNumber,
        pageCount: pageSize,
      })
      .then((res) => {
        setReciepients(res.data.list);
        console.log(res.data);
        setTotalCount(res.data.paging.totalCount);
        setPageNumber(res.data.paging.nextPageNumber);
      });
  };
  const handlePaginationClick = (e) => {
    setReciepients([]);

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}templatesto/getMailtemplates`, {
        searchText,
        tempID: id,
        page: e.selected,
        pageCount: pageSize,
      })
      .then((res) => {
        setReciepients(res.data.list);
        setTotalCount(res.data.paging.totalCount);
        setPageNumber(res.data.paging.nextPageNumber);
      });
  };
  const handlesearch = (e) => {
    setLoadBytes(false);
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}templatesto/getMailtemplates`, {
        searchText: e,
        tempID: id,
        page: pageNumber,
        pageCount: pageSize,
      })
      .then((res) => {
        setReciepients(res.data.list);
        setTotalCount(res.data.paging.totalCount);
        setPageNumber(0);
        setLoadBytes(true);
      });
  };
  return (
    <>
      <div
        className='modal fade'
        id='editModal'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Edit Template
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body row'>
              <div className='col-md-12'>
                <p>Mail Body:</p>
                <ReactQuill
                  theme='snow'
                  value={templateDataBody}
                  onChange={(e) => {
                    let temp = convertStringToHTML(templateData);
                    temp.getElementById("bodytochange").innerHTML = e;
                    document.getElementById("bodytochange").innerHTML = e;
                    setTemplateDataBody(e);
                    var rootElement = document.getElementById("templateviewer");

                    // Convert DOM to HTML string
                    var htmlString = rootElement.outerHTML;
                    setTemplateData(htmlString);
                  }}
                  modules={modules}
                  formats={formats}
                  bounds={"#root"}
                />
              </div>
              <div className='col-md-12'>
                <p>Mail Subject:</p>
                <input
                  type='text'
                  style={{ borderRadius: "50px", width: "100%" }}
                  onChange={(el) => {
                    setSubject(el.target.value);
                  }}
                  value={subject}
                ></input>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
              >
                Close
              </button>
              <button
                disabled={templateData == "" || subject == ""}
                type='button'
                className='btn btn-danger'
                data-dismiss='modal'
                onClick={EditTemplate}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ overflow: "hidden" }}>
        <div className='container-fluid p-0'>
          <div className='row' style={{ minHeight: "50vh" }}>
            <div className='col-md-2'>
              <SuperAdminNavBar></SuperAdminNavBar>
            </div>
            <div
              className={" col-md-10"}
              style={{ maxHeight: "100vh", overflow: "auto" }}
            >
              <div
                className='float-right mr-2 mt-2'
                style={{ display: "flex", alignItems: "center" }}
              >
                {TemplateType == "cert" ? (
                  <>
                    <a
                      href='/hackathon certificates template.xlsx'
                      download='hackathon certificates template'
                      target='_blank'
                    >
                      {" "}
                      <span
                        className='fa fa-download mr-2'
                        style={{
                          cursor: "pointer",
                          color: "#e60000",
                          fontSize: "16px",
                        }}
                      ></span>
                    </a>
                    <span
                      className='fa fa-upload mr-2'
                      style={{
                        cursor: "pointer",
                        color: "#e60000",
                        fontSize: "16px",
                      }}
                      onClick={() => {
                        document.getElementById("uploadlist").click();
                      }}
                    ></span>
                    <span
                      className='fa fa-image mr-2'
                      style={{
                        cursor: "pointer",
                        color: "#e60000",
                        fontSize: "16px",
                      }}
                      onClick={() => {
                        document.getElementById("imageupload").click();
                      }}
                    ></span>
                    <input
                      type='file'
                      id='uploadlist'
                      style={{ display: "none" }}
                      onChange={(e) => {
                        addsendtoList(e);
                      }}
                    ></input>
                    <input
                      type='file'
                      id='imageupload'
                      style={{ display: "none" }}
                      onChange={(e) => {
                        uploadImage(e);
                      }}
                    ></input>
                  </>
                ) : null}
                <span
                  className='fa fa-pencil mr-2'
                  style={{
                    cursor: "pointer",
                    color: "#e60000",
                    fontSize: "16px",
                  }}
                  data-toggle='modal'
                  data-target={"#editModal"}
                ></span>
                {TemplateType == "custom" ? (
                  <>
                    <span
                      className='fa fa-envelope mr-3'
                      style={{
                        cursor: "pointer",
                        color: "#e60000",
                        fontSize: "16px",
                      }}
                      onClick={() => {
                        sendEmail();
                      }}
                    ></span>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <div class='dropdown mb-2 mr-3'>
                        <button
                          class='btn btn-secondary dropdown-toggle'
                          type='button'
                          id='dropdownMenuButton'
                          data-toggle='dropdown'
                          aria-haspopup='true'
                          aria-expanded='false'
                          className={styles["ddl-btn"]}
                        >
                          {category}
                          <i class='fa fa-caret-down pl-5'></i>
                        </button>
                        <div
                          class='dropdown-menu'
                          aria-labelledby='dropdownMenuButton'
                        >
                          <a
                            class='dropdown-item'
                            onClick={() => {
                              setCategory("Only Me");
                            }}
                          >
                            Only Me
                          </a>
                          <a
                            class='dropdown-item'
                            onClick={() => {
                              setCategory("Passed Previous Panel");
                            }}
                          >
                            Passed Previous Panel
                          </a>
                          <a
                            class='dropdown-item'
                            onClick={() => {
                              setCategory("Failed Previous Panel");
                            }}
                          >
                            Failed Previous Panel
                          </a>
                          <a
                            class='dropdown-item'
                            onClick={() => {
                              setCategory("Passed Screening");
                            }}
                          >
                            Passed Screening
                          </a>
                          <a
                            class='dropdown-item'
                            onClick={() => {
                              setCategory("Didn't Finish Registration");
                            }}
                          >
                            Didn't Finish Registration
                          </a>
                          <a
                            class='dropdown-item'
                            onClick={() => {
                              setCategory("Failed Registration");
                            }}
                          >
                            Failed Registration
                          </a>
                        </div>
                      </div>
                      {category == "Passed Previous Panel" ||
                      category == "Failed Previous Panel" ? (
                        <div class='dropdown mb-2'>
                          <button
                            class='btn btn-secondary dropdown-toggle'
                            type='button'
                            id='dropdownMenuButton'
                            data-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'
                            className={styles["ddl-btn"]}
                          >
                            {Region}
                            <i class='fa fa-caret-down pl-5'></i>
                          </button>
                          <div
                            class='dropdown-menu'
                            aria-labelledby='dropdownMenuButton'
                          >
                            <a
                              class='dropdown-item'
                              onClick={() => {
                                setRegion("India");
                              }}
                            >
                              India
                            </a>
                            <a
                              class='dropdown-item'
                              onClick={() => {
                                setRegion("Romania");
                              }}
                            >
                              Romania
                            </a>
                            <a
                              class='dropdown-item'
                              onClick={() => {
                                setRegion("Egypt");
                              }}
                            >
                              Egypt
                            </a>
                          </div>
                        </div>
                      ) : null}
                    </span>
                  </>
                ) : (
                  <span
                    className='fa fa-envelope'
                    style={{
                      cursor: "pointer",
                      color: "#e60000",
                      fontSize: "16px",
                    }}
                    onClick={() => {
                      sendEmail();
                    }}
                  ></span>
                )}{" "}
              </div>
              {TemplateType == "custom" ? (
                <span>
                  you can use &#123;&#123;name&#125;&#125; to be replaced by
                  participant names only with Passed Previous Panel, failed
                  Previous Panel and Passed Screening
                </span>
              ) : null}
              <span
                style={{ wordBreak: "break-all" }}
                id='templateviewer'
                dangerouslySetInnerHTML={{ __html: templateData }}
              ></span>
              {TemplateType == "cert" ? (
                <>
                  <div
                    className={" col-md-12"}
                    style={{ maxHeight: "100vh", overflow: "auto" }}
                  >
                    <div
                      style={{
                        float: "right",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        className='fa fa-envelope pr-5'
                        style={{
                          cursor: "pointer",
                          color: "#e60000",
                          fontSize: "16px",
                        }}
                        onClick={() => {
                          sendToAll();
                        }}
                      ></span>

                      <input
                        className='mr-2'
                        type='text'
                        style={{ borderRadius: "10px" }}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                        value={searchText}
                      />
                      <button
                        onClick={() => handlesearch(searchText)}
                        className='fa fa-search'
                      ></button>
                    </div>
                  </div>
                  {loadBytes ? (
                    <div
                      className='main-postKeynotes-page-body row'
                      style={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <table className='table t-width'>
                        <thead>
                          <tr>
                            <th scope='col' style={{ width: "20%" }}>
                              Name
                            </th>
                            <th scope='col' style={{ width: "20%" }}>
                              Email
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {reciepients.map((el) => {
                            return (
                              <tr>
                                <td>{el.name}</td>
                                <td>{el.email}</td>
                                <td>{el.teamMembersCount}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tfoot>
                          <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            pageCount={Math.ceil(totalCount / pageSize)}
                            onPageChange={handlePaginationClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                          />
                        </tfoot>
                      </table>
                    </div>
                  ) : (
                    <div
                      className='main-postKeynotes-page-body row'
                      style={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <div className='text-center'>
                        <div
                          className='spinner-grow text-primary'
                          role='status'
                        >
                          <span className='sr-only'>Loading...</span>
                        </div>
                        <div
                          className='spinner-grow text-secondary'
                          role='status'
                        >
                          <span className='sr-only'>Loading...</span>
                        </div>
                        <div
                          className='spinner-grow text-success'
                          role='status'
                        >
                          <span className='sr-only'>Loading...</span>
                        </div>
                        <div className='spinner-grow text-danger' role='status'>
                          <span className='sr-only'>Loading...</span>
                        </div>
                        <div
                          className='spinner-grow text-warning'
                          role='status'
                        >
                          <span className='sr-only'>Loading...</span>
                        </div>
                        <div className='spinner-grow text-info' role='status'>
                          <span className='sr-only'>Loading...</span>
                        </div>
                        <div className='spinner-grow text-light' role='status'>
                          <span className='sr-only'>Loading...</span>
                        </div>
                        <div className='spinner-grow text-dark' role='status'>
                          <span className='sr-only'>Loading...</span>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewEmailTemplate;
